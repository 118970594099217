<template>
  <SlideComponent/>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import SlideComponent from './components/SlideComponent.vue';

@Options({
  components: {
    SlideComponent,
  },
})
export default class App extends Vue {}
</script>

<style>

</style>
